import axios from "axios";
import router from "../../router";

const verse = {
  namespaced: true,
  state: {
    // VERSE OF THE DAY
    verseLoading: false,
    verses: [],
    verseSnackbar: {
      open: false,
      details: null,
    },
    // -----------------------------------
  },
  getters: {
    // dayOfYear: () => {
    //   const getLength = number => number.toString().length;
    //   const today = new Date();
    //   let dayOfYear = Math.floor(
    //     // eslint-disable-next-line comma-dangle
    //     (today - new Date(today.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
    //   ).toString();
    //   if (getLength(parseInt(dayOfYear, 10)) === 1) {
    //     dayOfYear = `00${dayOfYear}`;
    //   } else if (getLength(parseInt(dayOfYear, 10)) === 2) {
    //     dayOfYear = `0${dayOfYear}`;
    //   }
    //   return dayOfYear;
    // }
    dayOfYear: () => {
      // Get today's date
      let today = new Date();

      // Subtract 4 years from today's date
      today.setFullYear(today.getFullYear() - 4);

      // Format the new date to YYYY-MM-DD
      let yearStr = today.getFullYear().toString().padStart(4, "0");
      let monthStr = (today.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
      let dayStr = today.getDate().toString().padStart(2, "0");

      return `${yearStr}-${monthStr}-${dayStr}`;
    },
  },
  mutations: {
    SET_VERSES(_state, _verses) {
      _state.verses = _verses;
    },
    SET_VERSE_LOADING(_state, _load) {
      _state.verseLoading = _load;
    },
    SET_VERSE_SNACKBAR(_state, _snackbar) {
      _state.verseSnackbar = _snackbar;
    },
  },
  actions: {
    getVerses({ commit }, verseId) {
      commit("SET_VERSE_LOADING", true);
      axios
        .get("https://gwrapi.herokuapp.com/verses/get_today")
        .then((res) => {
          if (res?.data?.image) {
            commit("SET_VERSES", [res.data]);
          } else {
            router.push("/");
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          router.push("/");
        })
        .finally(() => {
          commit("SET_VERSE_SNACKBAR", { open: false, details: null });
        });
    },
  },
};

export default verse;
